<template>
  <!-- Navbar logo-->
  <b-link
    to="/"
    class="navbar-brand"
  >
    <span class="brand-logo">
      <logo-img />
    </span>
  </b-link>
  <!-- /Navbar logo-->
</template>

<script>
import { BLink } from 'bootstrap-vue'
import LogoImg from '@/views/modules/core/partials/logo/LogoImg.vue'

export default {
  name: 'NavbarLogo',
  components: {
    LogoImg,
    BLink,
  },
}
</script>

<style scoped>

</style>
