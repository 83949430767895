<template>
  <li class="nav-item nav-search">

    <!-- Icon -->
    <a
      href="javascript:void(0)"
      class="nav-link nav-link-search"
      @click="showSearchBar = true"
    >
      <feather-icon
        icon="SearchIcon"
        size="21"
      />
    </a>

    <!-- Input -->
    <div
      class="search-input"
      :class="{'open': showSearchBar}"
    >
      <div class="search-input-icon">
        <feather-icon icon="SearchIcon" />
      </div>
      <!-- <input type="text" placeholder="Explore Vuexy...." class="form-control-input"> -->
      <!-- @keyup.esc="escPressed" -->
      <!-- @keyup.enter="suggestionSelected" -->
      <b-form-input
        v-if="showSearchBar"
        v-model="searchQuery"
        :placeholder="$t('Type 3 characters to search ...')"
        autofocus
        autocomplete="off"
        @keyup.up="increaseIndex(false)"
        @keyup.down="increaseIndex"
        @keyup.esc="showSearchBar = false; resetsearchQuery()"
        @keyup.enter="suggestionSelected"
        @blur.stop="showSearchBar = false; resetsearchQuery()"
      />
      <div
        class="search-input-close"
        @click="showSearchBar = false; resetsearchQuery()"
      >
        <feather-icon icon="XIcon" />
      </div>

      <!-- Suggestions List -->
      <vue-perfect-scrollbar
        :settings="perfectScrollbarSettings"
        class="search-list search-list-main scroll-area overflow-hidden"
        :class="{'show': searchQuery}"
        tagname="ul"
      >
        <ul v-if="Object.keys(filteredData).length > 0">
          <li
            v-for="(suggestion_list, grp_name, grp_index) in filteredData"
            :key="grp_index"
            class="suggestions-groups-list"
          >

            <!-- Group Header -->
            <p class="suggestion-group-title">
              <span>
                {{ title(grp_name) }}
              </span>
            </p>

            <!-- Suggestion List of each group -->
            <ul>
              <li
                v-for="(suggestion, index) in suggestion_list"
                :key="index"
                class="suggestion-group-suggestion cursor-pointer"
                :class="{'suggestion-current-selected': currentSelected === `${grp_index}.${index}`}"
                @mouseenter="currentSelected = `${grp_index}.${index}`"
                @mousedown.prevent="suggestionSelected(grp_name, suggestion)"
              >
                <b-link
                  :to="{ name: 'detail-view', params: { module: suggestion.module, id: suggestion.id } }"
                  class="p-0"
                >
                  <feather-icon
                    :icon="suggestion.icon"
                    class="mr-75"
                  />
                  <span class="align-middle">{{ suggestion.label }}</span>
                </b-link>
              </li>
            </ul>
          </li>
        </ul>
        <ul v-else>
          <li
            class="suggestion-group-suggestion no-results"
          >
            <p>No Results Found.</p>
          </li>
        </ul>
      </vue-perfect-scrollbar>
    </div>
  </li>
</template>

<script>
import {
  BFormInput, BLink,
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import { title } from '@core/utils/filter'
import router from '@/router'
import store from '@/store'
import api from '@/apis/portal'

const { getModuleIcon } = require('@/utils/utils')

export default {
  components: {
    BFormInput,
    BLink,
    VuePerfectScrollbar,
  },
  data() {
    return {
      filteredData: {},
      showSearchBar: false,
      searchQuery: '',
      title,
      currentSelected: -1,
      perfectScrollbarSettings: {
        maxScrollbarLength: 60,
      },
    }
  },
  watch: {
    searchQuery: 'search',
  },
  methods: {
    suggestionSelected(grpName, suggestion) {
      // If parameter is not provided => Use current selected
      if (!suggestion) {
        // If currentSelected value is -1 => No value/item is selected (Prevent Errors)
        /* eslint-disable no-use-before-define, no-param-reassign */
        if (this.currentSelected !== -1) {
          /* eslint-disable no-use-before-define, no-param-reassign */
          const [grpIndex, itemIndex] = this.currentSelected.split('.')
          grpName = Object.keys(this.filteredData)[grpIndex]
          suggestion = this.filteredData[grpName][itemIndex]
          /* eslint-enable */
        }
      }
      router.push({ name: 'detail-view', params: { module: suggestion.module, id: suggestion.id } }).catch(() => {})
      // eslint-disable-next-line no-use-before-define
      this.resetsearchQuery()
      this.showSearchBar = false
    },
    increaseIndex(val = true) {
      /* eslint-disable no-lonely-if, no-plusplus */

      // If there's no matching items
      if (!Object.values(this.filteredData).some(grpItems => grpItems.length)) return

      const [grpIndex, itemIndex] = this.currentSelected.split('.')

      const grpArr = Object.entries(this.filteredData)
      const activeGrpTotalItems = grpArr[grpIndex][1].length

      if (val) {
        // If active item is not of last item in grp
        if (activeGrpTotalItems - 1 > itemIndex) {
          this.currentSelected = `${grpIndex}.${Number(itemIndex) + 1}`

          // If active item grp is not last in grp list
        } else if (grpIndex < grpArr.length - 1) {
          for (let i = Number(grpIndex) + 1; i < grpArr.length; i++) {
            // If navigating group have items => Then move in that group
            if (grpArr[i][1].length > 0) {
              this.currentSelected = `${Number(i)}.0`
              break
            }
          }
        }
      } else {
        // If active item is not of first item in grp
        if (Number(itemIndex)) {
          this.currentSelected = `${grpIndex}.${Number(itemIndex) - 1}`

          // If active item grp  is not first in grp list
        } else if (Number(grpIndex)) {
          for (let i = Number(grpIndex) - 1; i >= 0; i--) {
            // If navigating group have items => Then move in that group
            if (grpArr[i][1].length > 0) {
              this.currentSelected = `${i}.${grpArr[i][1].length - 1}`
              break
            }
          }
        }
      }
      /* eslint-enable no-lonely-if, no-plusplus */
    },
    resetsearchQuery() {
      this.searchQuery = ''
    },
    search(val) {
      if (val.length >= 3) {
        api.searchRecords({ searchKey: this.searchQuery }).then(data => {
          const filteredData = {}
          Object.keys(data).forEach(module => {
            const searchData = data[module]
            const customSearchData = {}
            if (typeof searchData.uiLabel !== 'undefined') {
              const key = searchData.uiLabel
              delete searchData.uiLabel
              delete searchData.labelField
              Object.keys(searchData).forEach(index => {
                customSearchData[index] = {
                  ...searchData[index],
                  icon: getModuleIcon(module),
                  module,
                }
              })
              filteredData[key] = customSearchData
            }
          })
          this.filteredData = filteredData
        })
      }
      store.commit('app/TOGGLE_OVERLAY', Boolean(val))
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';

ul
{
  list-style: none;
  padding: 0;
  margin: 0;
}
p {
  margin: 0;
}

/* .app-auto-suggest {
  position: relative;
}

.auto-suggest-suggestions-list {
  box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1);
  border-radius: 6px;
  position: absolute;
  top: calc(100% + 1rem);
  background-color: white;
  width: 100%;
} */

.suggestion-group-title {
  font-weight: 500;
  padding: .75rem 1rem .25rem;
}

.suggestion-group-suggestion {
  padding: .75rem 1rem
}

.suggestion-current-selected {
  background-color: $body-bg;

  .dark-layout & {
    background-color: $theme-dark-body-bg;
  }
}
</style>
