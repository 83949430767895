<template>
  <p class="clearfix mb-0">
    <span class="float-md-left d-block d-md-inline-block mt-25">
      {{ $t('Copyright') }} © {{ localeDate(null,'YYYY') }}
      <span class="d-none d-sm-inline-block">, {{ $t('All rights Reserved') }}</span>
    </span>
  </p>
</template>

<script>
const { localeDate } = require('@/utils/utils')

export default {
  components: {
  },
  data() {
    return {
      localeDate,
    }
  },
}
</script>
